<template>
  <div>
    <div class="row m-0 p-0" ref="menu" v-if="menuItems">
      <div class="col-12 mb-2 bold" v-if="menuItemParentTitle">
        {{menuItemParentTitle}}
      </div>
      <div class="col-12 m-0 p-0" v-for="menuItem in menuItems" :key="menuItem.value">
        <menu-item :menuItem="menuItem" :isMobile="true" @selectItem="selectMenuItem"/>
      </div>
      <div class="col-12 pl-0 pr-4 mt-5 mb-2 align-self-end" v-if="menuItemsParent">
        <div class="row m-0 justify-content-between align-items-center">
          <back-button  @click="goBack" />
        </div>
      </div>
      <div class="col-12 p-0 pr-4 mt-5 mb-2 align-self-end">
        <div class="row m-0">
          <div class="col-12 mb-3 font5 bold color_beige clickable" @click="selectMenuItem(socialMediaMenuItem)">
            SOCIAL LINKS
          </div>
        </div>
        <div class="row m-0" v-if="false">
          <div class="col-12 mb-3 font5 bold color_beige clickable">
            <fb-icon class="mr-2"></fb-icon>
            <ig-icon class="mr-2"></ig-icon>
            <yt-icon></yt-icon>
          </div>
        </div>
        <div class="row m-0 mt-4">
          <div class="col-6 font5 regular color_white clickable" @click="logOut()">
            LOG OUT
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuItems, getMenuItem } from '@/helpers/menu.js'
import { mapActions } from 'vuex'
const fbIcon = () => import('@/icons/Facebook')
const igIcon = () => import('@/icons/Instagram')
const ytIcon = () => import('@/icons/YouTube')
export default {
  name: 'MobileMenu',
  components: {
    fbIcon,
    igIcon,
    ytIcon,
    'back-button': () => import('@/components/BackButton.vue'),
    'menu-item': () => import('@/components/MenuItem.vue')
  },
  props: {
    selectedMenuItem: {
      value: Object,
      default: null
    }
  },
  data () {
    return {
      menuItemParents: [],
      menuItemsParents: [],
      menuItems: null,
      socialMediaMenuItem: getMenuItem('socialMedia')
    }
  },
  watch: {
    selectedMenuItem: {
      handler (newVal) {
        console.log('WATCH')
        this.selectMenuItem(this.selectedMenuItem)
      }
    }
  },
  created () {
    this.initMenuItems()
  },
  computed: {
    menuItemParentTitle () {
      if (this.menuItemParents && this.menuItemParents.length > 0) {
        return this.menuItemParents[this.menuItemParents.length - 1].title
      }
      return null
    },
    menuItemsParent () {
      if (this.menuItemsParents && this.menuItemsParents.length > 0) {
        return this.menuItemsParents[this.menuItemsParents.length - 1]
      }
      return null
    }

  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    goBack () {
      this.menuItems = [...this.menuItemsParent]
      this.menuItemParents.pop()
      this.menuItemsParents.pop()
    },
    initMenuItems () {
      this.menuItems = menuItems.filter(mi => !mi.hidden)
      this.menuItemParents = []
      this.menuItemsParents = []
    },
    iconSrc (icon) {
      return require(`@/assets/images/${icon}`)
    },
    async logOut () {
      try {
        await this.logout()
      } catch (error) {
        console.error('Menu', error)
      }
      this.$router.push('/login')
    },
    selectMenuItem (mi) {
      if (mi.subItems && mi.subItems.length > 0) {
        this.menuItemParents.push(mi)
        this.menuItemsParents.push([...this.menuItems])
        this.menuItems = mi.subItems.filter(si => !si.hidden)
      } else {
        this.$emit('openPage', [...this.menuItemParents, mi])
      }
    }
  }
}
</script>
<style scoped>

.menu_item {
  /* background: rgba(127,182,191,0.7);
  background: linear-gradient(270deg, rgba(127,182,191,0.7) 0%, rgba(127,182,191,0.7) 10%, rgba(104,150,160,0.7) 25%, rgba(78,113,120,0.7) 45%, rgba(66,95,99,0.7) 70%, rgba(50,71,73,0.7) 100%); */
  height: 50px;
  cursor: pointer;
  border: 0.75px solid var(--dust);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.4vw;
}
@media (min-width: 1200px) {
  .menu_item {
    font-size: 20px;
  }
}
.menu_item_mobile {
  font-size: 20px;
}

.menu_item:hover {
  color: var(--white);
}
.menu_item.active {
  background: var(--beige);
  color: var(--white);
  font-weight: 600;
}
.icon {
  position: absolute;
  right: 1rem;
  height: 2.5rem;
  width: 1.5rem;
}
.temp_disabled {
  cursor: not-allowed;
  color: gray;
}
.back_btn {
  font-size: 16px;
}

</style>
